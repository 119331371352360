main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

main .cta {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}
